import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/homePages',
        meta: {
            isShow: false//判断二级路由是否显示
        },

    },

    {
        path: '/homePages',
        name: '首页',
        component: () => import('@/views/homePages/index'),
        meta: {
            isShow: false//判断二级路由是否显示
        },

    },
    /**
     * 关于我们
     *  -公司简介
     *  -我们的优势
     *  -发展历程
     */
    {
        path: '/ourAdvantage',
        name: '关于我们',
        component: ()=>import('@/views/aboutUs/OurAdvantage.vue'),
        // redirect: '/contactUs/ourAdvantage',
        meta: {
            isShow: false//判断二级路由是否显示
        },
    },
    /**
     * 荣誉资质
     */
    // {
    //     path: '/honorQualification',
    //     name: '荣誉资质',
    //     component: HonorQualification,
    //     meta: {
    //         isShow: false//判断二级路由是否显示
    //     },
    // },

    {
        path: '/products',
        name: '产品介绍',
        component: ()=>import('@/views/introductionToProducts/index'),
        meta: {
            isShow: false//判断二级路由是否显示
        },
        children: [
          
            {
                path: '/introductionToProducts/IntelligentRehabilitation',
                name: '智能康复',
                component: ()=>import('@/views/introductionToProducts/IntelligentRehabilitation'),
            },
            {
                path: '/introductionToProducts/visualManagement',
                name: '可视化管理',
                component: ()=>import('@/views/introductionToProducts/visualManagement'),
            }
        ]
    }
    ,
    {
        path: '/newsTrends',
        name: '新闻动态',
        component: ()=>import('@/views/newsTrends'),
        meta: {
            isShow: false//判断二级路由是否显示
        },
    },
    /**
     * 联系我们
     */
    {
        path: '/contactUs',
        name: '联系我们',
        component: ()=>import('@/views/contactUs/ContactUs.vue'),
        meta: {
            isShow: false//判断二级路由是否显示
        },
    },

    {
        path: '/managementSystem',
        // component: NotFound,
        name: '机构服务平台',
        meta: {
            isShow: false,//判断二级路由是否显示
            managementSystem: 'http://www.woniumm.com'
        },
    },
    {
        path: '*',
        component: ()=>import('@/views/notFound/'),
        meta: {
            isShow: false//判断二级路由是否显示
        },
    }

]

const router = new VueRouter({
    routes
})

export default router