<template>
    <div id="app">
        <router-view></router-view>
        <!-- 脚注 -->
        <div>
            <div class="footnote">
                <div class="footnote-content-box" ref="footnote">
                    <div>
                        <p class="text title">证书查询</p>
                    </div>
                    <div>
                        <p class="text title">关于我们</p>
                        <p class="text textDown" @click="moveTo('/contactUs/index')">公司简介</p>
                        <p class="text textDown" @click="moveTo('/contactUs/ourAdvantage')">发展历程</p>
                        <p
                            class="text textDown"
                            @click="moveTo('/contactUs/developmentCourse')"
                        >我们的优势</p>
                    </div>
                    <div>
                        <p class="text title">产品介绍</p>
                        <p class="text textDown" @click="moveTo('/products')">机构端</p>
                        <p class="text textDown" @click="moveTo('/products')">家长端</p>
                        <p class="text textDown" @click="moveTo('/products')">下载中心</p>
                    </div>
                    <div>
                        <p class="text title" @click="moveTo('/newsTrends/index')">新闻动态</p>
                        <p class="text textDown">公司动态</p>
                        <p class="text textDown">康复咨询</p>
                        <p class="text textDown">社会公益</p>
                    </div>
                    <div>
                        <p class="contact">服务热线</p>
                        <p class="phone-number">028-86660892</p>
                        <div class="two-code">
                            <img src alt />
                        </div>
                        <p class="two-code-text">扫码关注</p>
                    </div>
                </div>
            </div>
            <div class="ForTheRecord">
                <a
                    href="https://beian.miit.gov.cn/"
                    style="margin: 0 auto; color: #989898; text-decoration: none;"
                >
                    版权所有 2022-2122
                    &ensp;蜀ICP备2022016854号-2
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    mounted() {
        const footnote = this.$refs.footnote;
        footnote.style.visibility = "hidden";
        window.addEventListener("scroll", () => {
            const rect = footnote.getBoundingClientRect();
            const isInView =
                rect.top >= 0 &&
                rect.bottom - rect.height <= window.innerHeight;
            if (isInView) {
                footnote.style.visibility = "visible";
                footnote.classList.add("fade-in-up");
            }
        });
    },
    // watch:{
    //   '$route' (to,from){
    //     window.removeEventListener('scroll',()=>{})
    //   }
    // },
    methods: {
       
        moveTo(src, $event) {
            window.scrollTo({
                top: 0,
                behavior: "smooth" // 平滑滚动
            });
            if (src == this.$route.path) {
                return;
            }
            this.$router.push(src);
        }
    }
};
</script>

<style>
* {
    margin: 0;
    padding: 0;
}
</style>
<style lang="scss" scoped>
@import "/src/assets/css/animate.css";

.title {
    color: #333333;
    font-weight: bold;
}

.textDown {
    font-weight: 400;
    color: #333333;
    margin-top: 24px;
    cursor: pointer;
}

.textDown:hover {
    // text-decoration-line: underline;
    font-weight: bold;
    color: #000000;
}

.text {
    font-size: 18px;
    letter-spacing: 2px;
}

.footnote {
    // max-width: 1920px;
    min-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: space-between;
    border-top: 1px solid #999999;
    border-bottom: 1px solid #999999;

    .footnote-content-box {
        width: 1200px;
        height: 184px;
        padding: 40px 0;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: space-between;
    }
}

.contact {
    color: #999999;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.phone-number {
    font-size: 18px;
    color: #3569e2;
    letter-spacing: 2px;
    margin-top: 12px;
}

.two-code {
    width: 69px;
    height: 70px;
    margin: 0 auto;
    margin-top: 12px;
}

.two-code-text {
    text-align: center;
    color: #999999;
    font-size: 12px;
    letter-spacing: 2px;
}

.ForTheRecord {
    max-width: 1920px;
    min-width: 1200px;
    height: 60px;
    line-height: 60px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
}

.ForTheRecord > p {
    margin: 0 auto;
    color: #989898;
    font-size: 12px;
}
</style>
